import React from 'react'
import AddButtonComponent from './AddButtonComponent'
import { connect } from 'react-redux'
import { addLocalAction } from '../../../thunk/RemoteControlApi'
import LocalAction from '../../../classes/LocalAction'

class AddButton extends React.Component {
    constructor() {
        super()

        this.add = this.add.bind(this)
    }

    add = () => {
        let newLocalAction = new LocalAction('', 0, false)
        this.props.addLocalAction(newLocalAction)        
    }

    render() {
        return (
            <AddButtonComponent 
                add={this.add}
            />
        )
    }
}

export default connect(null, { addLocalAction })(AddButton)