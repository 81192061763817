import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    footer: {
        backgroundColor: theme.palette.primary.main,
        flexShrink: 0,
        color: 'white',
        textAlign: 'center'
    }
}))

export default function FooterComponent() {
    const classes = useStyles()

    return (
        <div>
            <footer className={classes.footer}></footer>
        </div>
    )
}