import React from 'react';
import { Button } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

// We can inject some CSS into the DOM.
const useStyles = makeStyles({
    root: {
        color: 'white',
    },
});

export default function LogoutComponent(props) {
    const classes = useStyles();

    return (
        <Button 
            onClick={props.logout}
            className={classes.root}
        >
            Logout
        </Button>
    )
}