import React from 'react';
import { withRouter } from 'react-router-dom';
import api from '../../utils/Api'
import history from '../../utils/History'
import LoginComponent from './LoginComponent';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loggedIn, loggedOut } from '../../store/reducers/Header'
import { setUsername } from '../../store/reducers/Login'
import SnackbarUtils from '../../utils/SnackbarConfigurator'
import { removeEmoji } from '../../utils/RemoveEmoji'

class Login extends React.Component {
	constructor() {
		super()

		this.state = {
			username: '',
			password: '',
			rememberMe: false,
			open: false,
			userHasError: false,
			userHasErrorText: '',
			passwordHasError: false,
			passwordHasErrorText: '',
			email: '',
			emailHasError: false,
			emailHasErrorText: ''
		}

		this.usernameInput = React.createRef()

		this.login = this.login.bind(this)
		this.isLoginValid = this.isLoginValid.bind(this)
		this.focusUsernameInput = this.focusUsernameInput.bind(this)
		this.installPWA = this.installPWA.bind(this)
	}

	focusUsernameInput() {
		this.usernameInput.current.focus()
	}

	componentDidMount() {
		this.props.loggedOut()
		api.logout()
	}

	handleChangeRememberMe = event => {
		const result = !this.state.rememberMe
		this.setState({ rememberMe: result })
	}

	modalHandleChange = () => {
		const result = !this.state.open
		this.setState({ open: result })
	}

	handleChangeUsername = event => {
		this.setState({ [event.target.name]: removeEmoji(event.target.value) })
		this.userValidation(event.target.value)
	}

	handleChangePassword = event => {
		this.setState({ [event.target.name]: event.target.value })
		this.passwordValidation(event.target.value)
	}

	login = () => {
		const username = this.state.username
		const password = this.state.password
		const rememberMe = this.state.rememberMe

		this.props.setUsername({ username: username })

		if (!this.isLoginValid(username, password)) {
			return;
		}

		api.login({
			username: username,
			password: password,
			rememberMe: rememberMe
		}).then((response) => {
			this.props.loggedIn()
			history.push('/list');
		}).catch((error) => {
			SnackbarUtils.error(error)
			this.focusUsernameInput()
		})
	}

	isLoginValid = (username, password) => {
		const validUsername = this.userValidation(username)
		const validPassword = this.passwordValidation(password)

		if (validUsername && validPassword) {
			return true
		}

		return false
	}

	userValidation = (username) => {
		if (username.length === 0) {
			this.setState({
				userHasError: true,
				userHasErrorText: 'Campo usuário não pode ser vazio'
			})
			return false
		} else {
			this.setState({
				userHasError: false,
				userHasErrorText: ''
			})
			return true
		}
	}

	passwordValidation = (password) => {
		if (password.length === 0) {
			this.setState({
				passwordHasError: true,
				passwordHasErrorText: 'Campo senha não pode ser vazio'
			})
			return false
		} else {
			this.setState({
				passwordHasError: false,
				passwordHasErrorText: ''
			})
			return true
		}
	}

	installPWA = () => {
		if (this.props.deferredPrompt) {
			this.props.deferredPrompt.prompt()
		}		
	}

	render() {
		return (
			<LoginComponent
				handleChangeRememberMe={this.handleChangeRememberMe}
				login={this.login}
				state={this.state}
				handleChangeUsername={this.handleChangeUsername}
				handleChangePassword={this.handleChangePassword}
				usernameInput={this.usernameInput}
				installPWA={this.installPWA}
				deferredPrompt={this.props.deferredPrompt}
			/>
		)
	}
}

const mapStateToProps = state => {
	return {
		show: state.messageAlert.show,
		isLogged: state.header.isLogged
	}
};
const mapDispatchToProps = dispatch => bindActionCreators({
	loggedIn,
	loggedOut,
	setUsername
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));
