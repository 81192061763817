import { localActionStorageHelper } from '../utils/LocalActionStorageHelper'
import {
    setLocalActionsList,
    setSelectedLocalActionIndex,
    overrideLocalActionFromList,
    setLastSentLocalAction
} from '../store/reducers/RemoteControl'
import '../store/reducers/'
import LocalAction from '../classes/LocalAction'
import { HandleSendErrorException } from '../utils/Exceptions'
import axios from 'axios';
import SnackbarUtils from '../utils/SnackbarConfigurator'

export const setLocalStorageKeyName = username => {
    return () => {
        localActionStorageHelper.setLocalStorageKeyName(username)
    }
}

export const loadCommands = () => {
    return dispatch => {
        const list = localActionStorageHelper.loadLocalActionsList();
        dispatch(setLocalActionsList(list));
    }
}

const saveLocalActionsListToLocalStorage = (list) => {
    localActionStorageHelper.saveLocalActionsList(list)
}

export const addLocalAction = (newLocalAction) => {
    return async (dispatch, getState) => {
        const state = getState();
        let list = [...state.remoteControl.localActionsList, newLocalAction]
        saveLocalActionsListToLocalStorage(list)
        dispatch(setLocalActionsList(list))
        dispatch(setSelectedLocalActionIndex(list.length - 1))
    }
}

export const clearLocalAction = (localActionId) => {
    return async (dispatch, getState) => {
        let state = getState()
        let localAction = state.remoteControl.localActionsList.find(x => x.id === localActionId);
        if (!localAction) {
            return
        }
        localAction.command = ''
        dispatch(overrideLocalActionFromList(localAction))
        state = getState()
        saveLocalActionsListToLocalStorage(state.remoteControl.localActionsList)
    }
}

export const removeLocalAction = (localActionId) => {
    return async (dispatch, getState) => {
        const state = getState()
        let localActionsList = state.remoteControl.localActionsList.filter(function (value, index) {
            return value.id !== localActionId
        })
        let index = (state.remoteControl.selectedLocalActionIndex - 1) < 0 ? 0 : state.remoteControl.selectedLocalActionIndex - 1

        dispatch(setLocalActionsList(localActionsList))
        dispatch(setSelectedLocalActionIndex(index))

        saveLocalActionsListToLocalStorage(localActionsList)

        if (localActionsList.length === 0) {
            let newLocalAction = new LocalAction(';) o/ Oi >< Tinbot!', 0, false)
            dispatch(addLocalAction(newLocalAction))
        }
    }
}

export const duplicateLocalAction = (localActionId) => {
    return async (dispatch, getState) => {
        const state = getState()
        let localAction = state.remoteControl.localActionsList.find(x => x.id === localActionId);
        if (!localAction) {
            return
        }
        let duplicatedLocalAction = new LocalAction(localAction.command, 0, false)
        dispatch(addLocalAction(duplicatedLocalAction))
    }
}

export const toggleIsFavorite = (localActionId) => {
    return async (dispatch, getState) => {
        let state = getState();
        let localAction = state.remoteControl.localActionsList.find(x => x.id === localActionId);
        if (!localAction) {
            return
        }
        localAction.isFavorite = !localAction.isFavorite
        dispatch(overrideLocalActionFromList(localAction))
        state = getState();
        dispatch(orderLocalActionsList(state.remoteControl.localActionsList))
        saveLocalActionsListToLocalStorage(state.remoteControl.localActionsList)
        state = getState();
        let index = state.remoteControl.localActionsList.findIndex(x => x.id === localActionId);
        if (index >= 0) {
            dispatch(setSelectedLocalActionIndex(index))
        }
    }
}

const getToken = () => {
    let token = '';

    if (sessionStorage.getItem('keycloak@token') === null) {
        if (localStorage.getItem('keycloak@token') == null) {                
            return null;
        } else {
            const key = JSON.parse(localStorage.getItem('keycloak@token'))
            token = key.access_token
        }
    } else {
        const key = JSON.parse(sessionStorage.getItem('keycloak@token'))
        token = key.access_token
    }

    return token;
}

export const sendLocalAction = (lastSentLocalAction, lastSentLocalActionDatetime, localActionId, leafTeamId) => {
    return async (dispatch, getState) => {
        let state = getState()
        let localAction = state.remoteControl.localActionsList.find(x => x.id === localActionId);
        if (!localAction) {
            return
        }

        if (localAction.command.trim() === '') {
            SnackbarUtils.error('Campo não pode ser vazio')
            return
        }

        if (lastSentLocalAction !== null && lastSentLocalActionDatetime) {
            if (lastSentLocalAction.command.trim() === localAction.command.trim()) {
                if (Math.abs((new Date().getTime() - lastSentLocalActionDatetime.getTime()) / 1000) < 10) {
                    SnackbarUtils.error('Troque o conteúdo da mensagem ou aguarde 10 segundos para enviar novamente')
                    return
                }
            }
        }

        let token = getToken();
        if (token === null) {
            SnackbarUtils.error('Falha na autenticação durante o envio do comando. Realize o login novamente')
            return;
        }

        const url = `${process.env.REACT_APP_BASE_URL}/api/robotdata/QueueActionScript/${leafTeamId}`
        var data = {
            "type": 'ticotico',
            "codeScript": localAction.command,
            "parameters": null,
            "origin": "pwa"
        };

        axios.post(url, data, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
        .then(() => {
            localAction.quantitySent = localAction.quantitySent + 1
            dispatch(setLastSentLocalAction({
                lastSentLocalAction: localAction,
                lastSentLocalActionDatetime: new Date()
            }))
            dispatch(overrideLocalActionFromList(localAction))
            state = getState()
            dispatch(orderLocalActionsList(state.remoteControl.localActionsList))
            state = getState();
            let index = state.remoteControl.localActionsList.findIndex(x => x.id === localActionId);
            if (index >= 0) {
                dispatch(setSelectedLocalActionIndex(index))
            }
            saveLocalActionsListToLocalStorage(state.remoteControl.localActionsList)
            SnackbarUtils.success('Comando enviado')
        })
        .catch(error => {
            SnackbarUtils.error(HandleSendErrorException(error))
        })
    }
}

const orderLocalActionsList = (list) => {
    return async (dispatch) => {
        let orderedList = list.sort((a, b) => {
            if (a.isFavorite === b.isFavorite) {
                if (a.quantitySent > b.quantitySent) return -1
                else return 1
            }
            else if (a.isFavorite) {
                return -1
            }
            else {
                return 1
            }
        })

        dispatch(setLocalActionsList(orderedList))
    }
}

export const changeVolume = (command, leafTeamId) => {
    return async (dispatch) => {
        let token = getToken()
        if (token === null) {
            SnackbarUtils.error('Falha na autenticação durante o envio do comando. Realize o login novamente')
            return;
        }

        const url = `${process.env.REACT_APP_BASE_URL}/api/robotdata/QueueActionScript/${leafTeamId}`
        var data = {
            "type": 'ticotico',
            "codeScript": command,
            "parameters": null,
            "origin": "pwa"
        };

        axios.post(url, data, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
        .then(() => {
            SnackbarUtils.success('Comando enviado')
        })
        .catch(error => {
            SnackbarUtils.error(HandleSendErrorException(error))
        })
    }
}