import React from 'react';
import { withRouter } from 'react-router-dom';
import LeafTeamListComponent from './LeafTeamListComponent';
import api from '../../utils/Api'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { loggedIn, robotNotSelected, robotSelected } from '../../store/reducers/Header'
import logo from '../../assets/logotinbot.png';
import { Grid, Container } from '@material-ui/core';
import history from '../../utils/History'
import { setSelectedLeafTeam } from '../../store/reducers/LeafTeam'
import SnackbarUtils from '../../utils/SnackbarConfigurator'

class LeafTeamList extends React.Component {
  constructor() {
    super()

    this.state = {
      tinbots: []
    }
    this.redirectToOptions = this.redirectToOptions.bind(this)
  }

  componentDidMount() {
    this.props.loggedIn()
    this.props.robotNotSelected()

    this.getTeamLeafsWithPermission()
  }

  redirectToOptions(leafTeamId, leafTeamName) {
    this.props.setSelectedLeafTeam({
      id: leafTeamId,
      name: leafTeamName
    })
    this.props.robotSelected()

    history.push('/options');
  }

  getTeamLeafsWithPermission() {
    const url = `${process.env.REACT_APP_BASE_URL}/api/time/AllLeafTeamsWithPermision`
    api.get(url)
      .then((result) => {
        this.setState({
          tinbots: result.data
        })
      })
      .catch((error) => {
        SnackbarUtils.error('Erro ao mostrar os robôs associados ao usuário')
      })
  }

  render() {
    return (
      <Container>
        <Grid container id="leaf-team-list-grid-container">
          <Grid item className="content-centralized">
            <div>
              <img src={logo} alt="logo-tinbot" />
            </div>
            <div>
              {
                this.state.tinbots.length > 0 ?
                  <span> <b>Selecione seu Tinbot!</b> </span>
                  :
                  <span> <b>Nenhum tinbot disponível!</b> </span>
              }
            </div>
          </Grid>
          <Grid item >
            {
              this.state.tinbots.length > 0
                ?
                <LeafTeamListComponent
                  tinbots={this.state.tinbots}
                  redirectToOptions={this.redirectToOptions}
                />
                :
                null
            }
          </Grid>
        </Grid>
      </Container>
    )
  }
}

const mapStateToProps = state => {
  return {
    show: state.messageAlert.show
  }
};
const mapDispatchToProps = dispatch => bindActionCreators({
  loggedIn,
  robotNotSelected,
  setSelectedLeafTeam,
  robotSelected
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LeafTeamList))