import { createStore, applyMiddleware, compose } from 'redux';
import Reducers from './reducers';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import thunk from "redux-thunk";

export const persistConfig = {
	key: 'root',
	storage,
	whitelist: ['leafTeam', 'login', 'header']
}

const composeEnhancers =
    typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
		: compose;

const persistedReducer = persistReducer(persistConfig, Reducers)
		
export const Store = createStore(persistedReducer, composeEnhancers(applyMiddleware(thunk)));
export const Persistor = persistStore(Store)