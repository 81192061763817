import React from 'react'
import AddIcon from '@material-ui/icons/Add'
import { Fab, makeStyles, MuiThemeProvider, Tooltip } from '@material-ui/core'
import { themeActionButton } from '../../../styles/Styles'

const useStyles = makeStyles(theme => ({
    fab: {
        position: 'absolute',
        bottom: theme.spacing(5),
        right: theme.spacing(1.5),
        zIndex: 2
    }
}));

export default function AddButtonComponent(props) {
    const classes = useStyles()

    return (
        <MuiThemeProvider theme={themeActionButton}>
            <Tooltip title="Adicionar">
                <Fab color="primary" aria-label="adicionar" className={classes.fab} onClick={props.add}>
                    <AddIcon style={{ fontSize: 40 }} />
                </Fab>
            </Tooltip>
        </MuiThemeProvider>
    )
}