import React from 'react';
import LogoutComponent from './LogoutComponent';

import api from '../../utils/Api'
import history from '../../utils/History'

import { loggedOut, loggedIn } from '../../store/reducers/Header'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

class Logout extends React.Component {
    constructor() {
        super()

        this.logout = this.logout.bind(this)
    }

    logout = () => {
        api.logout().then(() => {
            history.push("/")
        }).catch(function (error) {
        })
    }

    render() {
        return (
            <LogoutComponent logout={this.logout} />
        )
    }
}

const mapStateToProps = state => {
    return {}
};
const mapDispatchToProps = dispatch => bindActionCreators({
    loggedOut,
    loggedIn
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Logout)