import React from 'react';
import { withRouter } from 'react-router-dom';
import history from '../../utils/History'
import OptionsComponent from './OptionsComponent'
import { loggedIn, robotSelected } from '../../store/reducers/Header'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import SnackbarUtils from '../../utils/SnackbarConfigurator'
import BackButton from '../back-button/BackButton';

class Options extends React.Component {
	componentDidMount() {
		if (this.props.isRobotSelected) {
			this.props.loggedIn()
			this.props.robotSelected()
		} else {
			SnackbarUtils.warning('Robô não selecionado')
			history.push('/list')
		}
	}

	toRemoteControl = () => {
		history.push('/remote-control')
	}

	render() {
		return (
			<div className="group-multiple-components">
				<OptionsComponent
					toRemoteControl={this.toRemoteControl}
				/>
				<BackButton path="/list"/>
			</div>
		)
	}
}

const mapStateToProps = state => {
	return {
		isRobotSelected: state.header.isRobotSelected
	}
};
const mapDispatchToProps = dispatch => bindActionCreators({
	loggedIn,
	robotSelected
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Options))