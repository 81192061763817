import React from 'react';

import MessageAlertComponent from './MessageAlertComponent'
import { MessageAlertEnum } from './MessageAlertEnum';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { closeAlert, showAlert } from '../../store/reducers/MessageAlert'

class MessageAlert extends React.Component {
    // const[messageInfo, setMessageInfo] = React.useState(undefined);
    constructor() {
        super()

        this.state = {
            id: '',
            message: '',
            variant: 'default',
            open: false
        }
    }

    componentDidUpdate() {
        if (this.state.open) {
            // immediately begin dismissing current message
            // to start showing new one
            this.setState({ open: false })
        } else {
            this.processQueue();
        }
    }

    processQueue = () => {
        if (this.props.queue.length > 0) {
            const content = this.props.queue.shift()
            this.setState({
                id: content.id,
                message: content.message,
                variant: content.variant,
                open: true
            })
        }
    };

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({
            open: false
        })
    };

    handleExited = () => {
        this.processQueue();
    };

    setCustomStyle = (backgroundColor, color) => {
        return {
            backgroundColor: backgroundColor,
            color: color
        }
    }

    // chooseMessageAlertToDisplay = () => {
    //     let customStyle = {}

    //     switch (this.props.severity) {
    //         case MessageAlertEnum.success:
    //             customStyle = this.setCustomStyle('green', 'white')
    //             break;
    //         case MessageAlertEnum.warning:
    //             customStyle = this.setCustomStyle('#EE7600', 'white')
    //             break;
    //         case MessageAlertEnum.info:
    //             customStyle = this.setCustomStyle('blue', 'white')
    //             break;
    //         case MessageAlertEnum.error:
    //             customStyle = this.setCustomStyle('red', 'white')
    //             break;
    //         default:
    //             customStyle = this.setCustomStyle('black', 'white')
    //             break;
    //     }

    //     return <MessageAlertComponent
    //         open={this.props.show}
    //         message={this.props.message}
    //         closeAlert={this.props.closeAlert}
    //         color={customStyle.color}
    //         backgroundColor={customStyle.backgroundColor}
    //     />
    // }

    chooseMessageAlertToDisplay = () => {
        let customStyle = {}

        switch (this.state.variant) {
            case MessageAlertEnum.success:
                customStyle = this.setCustomStyle('green', 'white')
                break;
            case MessageAlertEnum.warning:
                customStyle = this.setCustomStyle('#EE7600', 'white')
                break;
            case MessageAlertEnum.info:
                customStyle = this.setCustomStyle('blue', 'white')
                break;
            case MessageAlertEnum.error:
                customStyle = this.setCustomStyle('red', 'white')
                break;
            default:
                customStyle = this.setCustomStyle('black', 'white')
                break;
        }

        // return <MessageAlertComponent
        //     open={this.props.show}
        //     message={this.props.message}
        //     closeAlert={this.props.closeAlert}
        //     color={customStyle.color}
        //     backgroundColor={customStyle.backgroundColor}
        // />
        return <MessageAlertComponent
            open={this.state.open}
            id={this.state.id}
            message={this.state.message}
            handleClose={this.handleClose}
            handleExited={this.handleExited}
            color={customStyle.color}
            backgroundColor={customStyle.backgroundColor}
        />
    }

    render() {
        return (
            <div>
                {
                    this.props.queue.length > 0
                        ?
                        this.chooseMessageAlertToDisplay()
                        : null
                }
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        show: state.messageAlert.show,
        message: state.messageAlert.message,
        severity: state.messageAlert.severity,
        queue: state.messageAlert.queue
    }
};
const mapDispatchToProps = dispatch => bindActionCreators({
    showAlert,
    closeAlert
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MessageAlert);