import React from 'react'
import ForgotPasswordComponent from "./ForgotPasswordComponent";

import api from '../../utils/Api'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { openDialog, setOpen } from '../../store/reducers/ForgotPassword'
import SnackbarUtils from '../../utils/SnackbarConfigurator'

class ForgotPassword extends React.Component {
    constructor() {
        super()

        this.state = {
            email: '',
            hasError: false,
            errorText: '',
            length: 0
        }

        this.emailInput = React.createRef()

        this.handleChange = this.handleChange.bind(this)        
    }

    focusEmailInput() {
		this.emailInput.current.focus()
	}

    handleChange = event => {
        this.setState({ 
            [event.target.name]: event.target.value,
            length: event.target.value.length
        })
    }

    sendEmail = () => {
        if (!this.isEmailValid(this.state.email)) {
            return
        }

        api.forgotPassword(this.state.email)
            .then((result) => {
                SnackbarUtils.success('E-mail enviado')
                this.props.setOpen(false)
            })
            .catch(error => {
                SnackbarUtils.error(error)
                this.focusEmailInput()
            })
    }

    isEmailValid = (email) => {
        if (email.length === 0) {
            this.setState({
                hasError: true,
                errorText: 'Campo email não pode ser vazio'
            })
            return false;
        }

        const emailRegex = /^[A-Za-z0-9](([_.-]?[a-zA-Z0-9]+)*)@([A-Za-z0-9]+)(([.-]?[a-zA-Z0-9]+)*)\.([A-Za-z]{2,})$/

        if (!emailRegex.test(email)) {
            this.setState({
                hasError: true,
                errorText: 'E-mail inválido'
            })
            return false;
        }

        this.setState({
            hasError: false,
            errorText: ''
        })
        return true
    }

    render() {
        const { open, openDialog } = this.props

        return (
            <ForgotPasswordComponent
                openDialog={openDialog}
                open={open}
                state={this.state}
                handleChange={this.handleChange}
                sendEmail={this.sendEmail}
                emailInput={this.emailInput}
            />
        )
    }
}

const mapStateToProps = state => { return { 
    open: state.forgotPassword.open,
    show: state.messageAlert.show
} };
const mapDispatchToProps = dispatch => bindActionCreators({ 
    openDialog, 
    setOpen,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);