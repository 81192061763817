import React from 'react';
import { Backdrop, CircularProgress, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1300,
      color: '#fff',
    },
  }));

export default function LoadingComponent(props) {
    const classes = useStyles()

    return(
        <Backdrop className={classes.backdrop} open={props.show}>
            <CircularProgress color="inherit" />
        </Backdrop>
    )
} 