import { createAction, handleActions } from 'redux-actions';

export const openDialog = createAction('OPEN_DIALOG')
export const setOpen = createAction('SET');

const initialState = {
	open: false
};

export default handleActions(
	{
		[openDialog]: state => ({ ...state, open: !state.open }),
		[setOpen]: (state, action) => ({
			...state,
			open: action.payload
		})
	},
	initialState
);
