import { createMuiTheme } from "@material-ui/core";
import { green, red, indigo } from "@material-ui/core/colors";

export const themeButtonSend = createMuiTheme({
    palette: {
        primary: green
    } 
})

export const themeButtonFavorite = createMuiTheme({
    palette: {
        primary: red
    }
})

export const themeActionButton = createMuiTheme({
    pallete: {
        primary: indigo
    }
})