import React from 'react'
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import { TextField } from '@material-ui/core';
import { setSelectedLocalActionIndex, setSelectedCommandText } from '../../../store/reducers/RemoteControl'
import { removeEmoji } from '../../../utils/RemoveEmoji'

class ListTextarea extends React.Component {
    constructor() {
        super()        

        this.handleOnChange = this.handleOnChange.bind(this)
        this.handleOnFocus = this.handleOnFocus.bind(this)
    }

    componentDidUpdate(prevProps) {
        if (prevProps.localActionsList.length < this.props.localActionsList.length) {
            this.scrollToLastLocalAction()
        }
    }

    scrollToLastLocalAction() {
        this.props.containerRef.current.scrollTop = this.props.containerRef.current.scrollHeight
    }

    handleOnFocus = (newIndex) => {
        if (newIndex !== this.props.selectedLocalActionIndex) {
            this.props.setSelectedLocalActionIndex(newIndex)
        }
    }

    handleOnChange = (event) => {
        const value = removeEmoji(event.target.value) 
        this.props.setSelectedCommandText(value)
    }

    render() {
        return (
            <Grid container direction="column" id="list-textarea-grid-container" ref={this.props.containerRef}>
                {
                    this.props.localActionsList
                        ?
                        this.props.localActionsList.map((element, index) => {
                            return (
                                <Grid item key={element.id} className={[
                                    "grid-content",
                                    index === this.props.selectedLocalActionIndex ? "selected" : "",
                                ].join(' ')}>
                                    <TextField
                                        id={element.id}
                                        value={element.command}
                                        name={element.id}
                                        multiline
                                        label=""
                                        onFocus={(e) => this.handleOnFocus(index)}
                                        onChange={this.handleOnChange}
                                        className={
                                            [
                                                "local-command-item",
                                                element.isFavorite ? "favorite-action" : "non-favorite-action",
                                            ].join(' ')
                                        }
                                        inputProps={{
                                            maxLength: 600,
                                            className: "textarea-local-command-item"
                                        }}
                                        rows={index === this.props.selectedLocalActionIndex ? null : 1}
                                        autoFocus={index === this.props.selectedLocalActionIndex ? true : false}
                                    />
                                </Grid>
                            )
                        })
                        :
                        null
                }
            </Grid>
        )
    }
}

const mapStateToProps = state => {
    return {
        localActionsList: state.remoteControl.localActionsList,
        selectedLocalActionIndex: state.remoteControl.selectedLocalActionIndex
    }
};

const mapDispatchToProps = {
    setSelectedLocalActionIndex,
    setSelectedCommandText
};

export default connect(mapStateToProps, mapDispatchToProps)(ListTextarea)