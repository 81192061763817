import React from 'react';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import { FormHelperText } from '@material-ui/core';

export default function ForgotPasswordComponent(props) {
	return (
		<div clasas="forgot-password">
			<div className="Reset-password">
				<Button 
					color="primary"
					onClick={props.openDialog}
					size="small"
				>
					Esqueceu sua senha?
				</Button>
			</div>
			<Dialog 
				onClose={props.openDialog} 
				open={props.open}
				fullWidth={true}
			>
				<div className="Reset-modal">
					<div className="Text-center">
						<span >
							Recuperação de senha
						</span>
						<br />
					</div>
					<TextField
						label="E-mail"
						className="Full-width"
						name="email"
						value={props.state.email}
						onChange={props.handleChange}
						margin="normal"
						error={props.state.hasError}
						helperText={props.state.hasError ? props.state.errorText : ''}
						inputProps={{
                            maxLength: 200,
						}}
						aria-describedby="component-helper-text"
						autoComplete="off"
						autoFocus={true}
						inputRef={props.emailInput}
					/>
					<FormHelperText id="component-helper-text"> {props.state.length} caractere(s) de 200 caracteres</FormHelperText>
					<div className="Text-center">
						<Button variant="contained" className="Buttons Secondary-color" onClick={props.sendEmail}>
							Enviar email
						</Button>
					</div>
				</div>
			</Dialog>
		</div>
	)
}    
