import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { Icon } from '@material-ui/core';
import { loadCSS } from 'fg-loadcss';
import AddBox from '@material-ui/icons/AddBox'

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(0),
        top: theme.spacing(0),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose} size="small">
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(4),
        display: 'inline',
        verticalAlign: 'middle'
    },
}))(MuiDialogContent);

export default function DialogPWA(props) {
    React.useEffect(() => {
        loadCSS(
          'https://use.fontawesome.com/releases/v5.1.0/css/all.css',
          document.querySelector('#font-awesome-css'),
        );
      }, []);

    return (
        <Dialog onClose={props.handleClose} aria-labelledby="customized-dialog-title" open={props.open}>
            <DialogTitle id="customized-dialog-title" onClose={props.handleClose}>
                Adicione Tinbot App na sua Tela de Início!
            </DialogTitle>
            <DialogContent dividers>
                Instale esse aplicativo WEB no seu iPhone: 
                <ol>
                    <li>
                        Aperte o botão de compartilhamento 
                        <Icon className="fas fa-sign-out-alt ios-share-icon" fontSize="small"/>
                    </li>
                    <li>
                        Clique em Adicione a Tela de Início 
                        <AddBox className="ios-add-icon" fontSize="small"/>
                    </li>
                </ol>
            </DialogContent>
        </Dialog>
    );
}