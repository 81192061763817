import React from 'react';
import ActionsBarComponent from './ActionsBarComponent';
import {
    clearLocalAction,
    removeLocalAction,
    duplicateLocalAction,
    toggleIsFavorite,
    sendLocalAction,
    changeVolume
} from '../../../thunk/RemoteControlApi'
import { connect } from 'react-redux';

class ActionsBar extends React.Component {
    constructor() {
        super()

        this.clearLocalAction = this.clearLocalAction.bind(this)
        this.removeLocalAction = this.removeLocalAction.bind(this)
        this.duplicateLocalAction = this.duplicateLocalAction.bind(this)
        this.toggleIsFavorite = this.toggleIsFavorite.bind(this)
        this.sendLocalAction = this.sendLocalAction.bind(this)
        this.volumeDown = this.volumeDown.bind(this)
        this.volumeUp = this.volumeUp.bind(this)
    }    

    clearLocalAction() {
        if (!this.props.selectedLocalAction) {
            return
        }
        this.props.clearLocalAction(this.props.selectedLocalAction.id)
    }

    removeLocalAction() {
        if (!this.props.selectedLocalAction) {
            return
        }
        this.props.removeLocalAction(this.props.selectedLocalAction.id)
    }

    duplicateLocalAction() {
        if (!this.props.selectedLocalAction) {
            return
        }
        this.props.duplicateLocalAction(this.props.selectedLocalAction.id)
    }

    toggleIsFavorite() {
        if (!this.props.selectedLocalAction) {
            return
        }
        this.props.toggleIsFavorite(this.props.selectedLocalAction.id)
    }

    sendLocalAction() {
        if (!this.props.selectedLocalAction || !this.props.leafTeamId) {
            return
        }
        this.props.sendLocalAction(
            this.props.lastSentLocalAction, 
            this.props.lastSentLocalActionDatetime, 
            this.props.selectedLocalAction.id, 
            this.props.leafTeamId
        )
    }    

    volumeUp() {
        if (!this.props.leafTeamId) {
            return
        }
        this.props.changeVolume("{volume;change=25} mais alto", this.props.leafTeamId)
    }

    volumeDown() {
        if (!this.props.leafTeamId) {
            return
        }
        this.props.changeVolume("{volume;change=-25} mais baixo", this.props.leafTeamId)
    }

    render() {
        return (
            <ActionsBarComponent
                clear={this.clearLocalAction}
                remove={this.removeLocalAction}
                duplicate={this.duplicateLocalAction}
                favorite={this.toggleIsFavorite}
                send={this.sendLocalAction}
                volumeUp={this.volumeUp}
                volumeDown={this.volumeDown}
                actionsBarRef={this.props.actionsBarRef}
            />
        )
    }
}

const mapStateToProps = state => {
    return {
        selectedLocalAction: state.remoteControl.localActionsList[state.remoteControl.selectedLocalActionIndex],
        leafTeamId: state.leafTeam.id,
        lastSentLocalAction: state.remoteControl.lastSentLocalAction,
        lastSentLocalActionDatetime: state.remoteControl.lastSentLocalActionDatetime,
    }
};

const mapDispatchToProps = {
    clearLocalAction,
    removeLocalAction,
    duplicateLocalAction,
    toggleIsFavorite,
    sendLocalAction,
    changeVolume
}
export default connect(mapStateToProps, mapDispatchToProps)(ActionsBar)