import { createAction, handleActions } from 'redux-actions';

export const setSelectedLeafTeam = createAction('SET_SELECTED_LEAF_TEAM')

const initialState = {
    id: '',
    name: '',
};

export default handleActions(
	{
		[setSelectedLeafTeam]: (state, action) => ({ 
            ...state, 
            id: action.payload.id,
            name: action.payload.name
        }),
	},
	initialState
);
