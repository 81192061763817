import React from 'react';

import Logout from '../logout/Logout';
import BackButton from '../back-button/BackButton';

import { makeStyles, Grid, AppBar } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  header: {
    minHeight: '36px'
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap'
  },
  itemLeafTeamName: {
    fontWeight: 'bolder',
    flex: 1,
    minWidth: 0,
    paddingLeft: '5px'
  },
}))

const loggedHeaderWithRobotName = (props, classes) => {
  return (
    <AppBar position="static" className={classes.header}>
      <Grid container className={classes.container}>
        <Grid item>
          <BackButton />
        </Grid>
        <Grid item className={classes.itemLeafTeamName}>
          <span>{props.selectedLeafTeamName}</span>
        </Grid>
        <Grid item>
          <Logout />
        </Grid>
      </Grid>
    </AppBar>
  )
}

const loggedHeaderWithoutRobotName = (props, classes) => {
  return (
    <AppBar position="static" className={classes.header}>
      <Grid container className={classes.container}>
        <Grid item className={classes.itemLeafTeamName}></Grid>
        <Grid item>
          <Logout />
        </Grid>
      </Grid>
    </AppBar>
  )
}

const notLogged = (classes) => {
  return (
    <AppBar position="static" className={classes.header}>
      <Grid container className={classes.container}>
        <Grid item className={classes.itemLeafTeamName}></Grid>
        <Grid item className={classes.itemLogout}>
        </Grid>
      </Grid>
    </AppBar>
  )
}

export default function HeaderComponent(props) {
  const classes = useStyles()

  return (
    <div>
      {
        props.isLogged ?
          props.isRobotSelected ?
            loggedHeaderWithRobotName(props, classes)
            :
            loggedHeaderWithoutRobotName(props, classes)
          :
          notLogged(classes)
      }
    </div>
  )
}