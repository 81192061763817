import React from 'react'
import { isInStandaloneMode, isIOS } from '../../utils/Device'
import { Grid, Button } from '@material-ui/core'
import DialogPWA from './DialogPWA'

class ButtonPWA extends React.Component {
    constructor() {
        super()

        this.state = {
            openDialog: false
        }

        this.installPWA = this.installPWA.bind(this)
    }

    installPWA = () => {
        if (this.props.deferredPrompt) {
            this.props.deferredPrompt.prompt()
        }
    }

    handleClickOpen = () => {
        this.setState({
            openDialog: true
        })
    };

    handleClose = () => {
        this.setState({
            openDialog: false
        })
    };

    render() {
        return (
            <Grid item className="login-grid-container-item button-centralized">
                {
                    this.props.deferredPrompt
                        ?
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={this.installPWA}
                            id="button-install-pwa"
                        >
                            Adicione Tinbot App na sua Tela de Início!
                        </Button>
                        :
                        isIOS() && !isInStandaloneMode()
                            ?
                            <div>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={this.handleClickOpen}
                                    id="button-install-pwa"
                                >
                                    Adicione Tinbot App na sua Tela de Início!
                                </Button>
                                <DialogPWA handleClose={this.handleClose} open={this.state.openDialog}/>
                            </div>
                            :
                            null
                }
            </Grid>
        )
    }
}

export default ButtonPWA