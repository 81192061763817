import React from 'react'
import history from '../../utils/History'
import BackButtonComponent from './BackButtonComponent'

class BackButton extends React.Component {
    constructor() {
        super()

        this.goBack = this.goBack.bind(this)
    }

    goBack = () => {
        history.goBack();
    }

    render() {
        return (
            <BackButtonComponent goBack={this.goBack} />
        )
    }
}

export default BackButton