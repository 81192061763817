import React from 'react'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { makeStyles, IconButton } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    root: {
        color: 'white',
    },
    fab: {
        position: 'absolute',
        bottom: theme.spacing(5),
        left: theme.spacing(1.5),
        zIndex: 2,
        backgroundColor: theme.palette.primary
    }
}));

export default function BackButtonComponent(props) {
    const classes = useStyles()

    return (
        <IconButton aria-label="voltar" className={classes.root} onClick={props.goBack} size="small">
            <ArrowBackIcon />
        </IconButton>
    )
}