import { createAction, handleActions } from 'redux-actions';

const initialState = {
	localActionsList: [],
	selectedLocalActionIndex: 0,
	lastSentLocalAction: null,
	lastSentLocalActionDatetime: new Date()
};

export const setLocalActionsList = createAction('SET_LOCAL_ACTION_LIST')
export const setSelectedLocalActionIndex = createAction('setSelectedLocalActionIndex');
export const setSelectedCommandText = createAction('setSelectedCommandText');
export const overrideLocalActionFromList = createAction('overrideLocalActionFromList');
export const setLastSentLocalAction = createAction('setLastSentLocalAction');

export default handleActions(
	{
		[setLocalActionsList]: (state, action) => ({ ...state, localActionsList: action.payload }),
		[setSelectedLocalActionIndex]: (state, action) => ({ ...state, selectedLocalActionIndex: action.payload }),
		[overrideLocalActionFromList]: (state, action) => {
			let localActionsList = state.localActionsList.map((x) => {
				if (x.id === action.payload.id) {
					return { ...x, 
						id: action.payload.id,
						command: action.payload.command, 
						quantitySent: action.payload.quantitySent,
						isFavorite: action.payload.isFavorite 
					};
				}
				else
					return x;
			})
			return { ...state, localActionsList };
		},
		[setSelectedCommandText]: (state, action) => {
			let localActionsList = state.localActionsList.map((x, i) => {
				if (i === state.selectedLocalActionIndex) {
					return { ...x, command: action.payload };
				}
				else
					return x;
			})
			return { ...state, localActionsList };
		},
		[setLastSentLocalAction]: (state, action) => ({ ...state, 
			lastSentLocalAction: action.payload.lastSentLocalAction,
			lastSentLocalActionDatetime: action.payload.lastSentLocalActionDatetime,
		}),
	},
	initialState
);


