import React, { Component } from "react";
import './App.css';
import OurRouter from './routes';
import Header from "./components/header/Header";
import MessageAlert from "./components/message-alert/MessageAlert";
import LoadingComponent from "./components/loading/LoadingComponent";
import api from './utils/Api'
import { Provider } from "react-redux";
import { Persistor, Store } from './store/Index'
import { PersistGate } from "redux-persist/integration/react";
import { createMuiTheme, Grid } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import { SnackbarProvider } from 'notistack';
import { SnackbarUtilsConfigurator } from "./utils/SnackbarConfigurator";
import FooterComponent from "./components/footer/FooterComponent";

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#757ce8',
      main: '#3f50b5',
      dark: '#002884',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#000',
    },
  },
});

export default class App extends Component {
  constructor() {
    super();

    this.state = {
      deferredPrompt: '',
      messageAlertOpen: false,
      messageAlertText: '',
      messageAlertSeverity: '',
      showLoader: false
    }

    this.showLoader = this.showLoader.bind(this)
    this.hideLoader = this.hideLoader.bind(this)
  }

  componentDidMount = () => {
    api.configureInterceptors(this.showLoader, this.hideLoader)

    window.addEventListener('appinstalled', (evt) => {
      this.setState({ deferredPrompt: '' });
      alert('Tinbot App adicionado');
    });

    window.addEventListener('beforeinstallprompt', e => {
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      // alert('PREVENT DEFAULT')
      e.preventDefault();
      // Stash the event so it can be triggered later.
      // console.log(e)
      // alert('ADD EVENT LIST')
      this.setState({ deferredPrompt: e });
    });
  }

  openMessageAlert = (severity, text) => {
    const result = !this.state.messageAlertOpen
    this.setState({
      messageAlertOpen: result,
      messageAlertText: text,
      messageAlertSeverity: severity
    })
  }

  closeMessageAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({ messageAlertOpen: false })
  };

  showLoader = () => {
    this.setState({ showLoader: true })
  }

  hideLoader = () => {
    this.setState({ showLoader: false })
  }

  render() {
    return (
      <Provider store={Store}>
        <PersistGate persistor={Persistor}>
          <ThemeProvider theme={theme}>
            <SnackbarProvider
              maxSnack={1}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              hideIconVariant
            >
              <SnackbarUtilsConfigurator />
              <Grid container id="main-grid-container">
                <Grid item>
                  <Header />
                </Grid>
                <Grid item id="main-grid-container-item">
                  <div id="main-grid-container-item-content">
                    <OurRouter
                      openMessageAlert={this.openMessageAlert}
                      deferredPrompt={this.state.deferredPrompt}
                    />
                  </div>
                </Grid>
                <Grid item>
                  <FooterComponent />
                </Grid>
              </Grid>
              <MessageAlert />
              <LoadingComponent show={this.state.showLoader} />
            </SnackbarProvider>
          </ThemeProvider>
        </PersistGate>
      </Provider>
    );
  }
}

