import React from 'react';
import { Divider, Fab, Grid, MuiThemeProvider, Tooltip } from '@material-ui/core';
import PlayCircleFilledIcon from '@material-ui/icons/PlayArrow';
import Crop169Icon from '@material-ui/icons/Crop169';
import DeleteIcon from '@material-ui/icons/Delete';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import VolumeDownIcon from '@material-ui/icons/VolumeDown';
import { makeStyles } from "@material-ui/core";
import { themeButtonFavorite, themeButtonSend } from '../../../styles/Styles'

const useStyles = makeStyles(theme => ({
    appBar: {
        backgroundColor: theme.palette.primary.light,
        position: 'fixed',
        zIndex: 1,
        width: '100%',
        minHeight: '64px',
    },
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    button: {
        margin: theme.spacing(0.5),
    },
    divider: {
        alignSelf: 'stretch',
        height: 'auto',
        width: 2
    },
    itemOtherActions: {
        display: 'flex',
        alignItems: 'center'
    },
    sendAction: {
        alignItems: 'center'
    },
    buttonSend: {
        margin: theme.spacing(0.5),
    },
}));

export default function ActionsBarsComponent(props) {
    const classes = useStyles();

    return (
        <div className={classes.appBar} ref={props.actionsBarRef}>
            <Grid container className={classes.container}>
                <Grid item className={classes.itemOtherActions}>
                    <Tooltip title="Limpar">
                        <Fab color="primary" aria-label="limpar" size="small" className={classes.button} onClick={props.clear}>
                            <Crop169Icon style={{ fontSize: 25, color: 'white' }} />
                        </Fab>
                    </Tooltip>
                    <Tooltip title="Remover">
                        <Fab color="primary" aria-label="remover" size="small" className={classes.button} onClick={props.remove}>
                            <DeleteIcon style={{ fontSize: 25, color: 'white' }} />
                        </Fab>
                    </Tooltip>
                    <Tooltip title="Duplicar">
                        <Fab color="primary" aria-label="duplicar" size="small" className={classes.button} onClick={props.duplicate}>
                            <FileCopyIcon style={{ fontSize: 25, color: 'white' }} />
                        </Fab>
                    </Tooltip>                    
                    <MuiThemeProvider theme={themeButtonFavorite}>
                        <Tooltip title="Favoritar">
                            <Fab color="primary" aria-label="favoritar" size="small" className={classes.button} onClick={props.favorite}>
                                <FavoriteIcon style={{ fontSize: 25, color: 'white' }} />
                            </Fab>
                        </Tooltip>
                    </MuiThemeProvider>
                    <Divider orientation="vertical" className={classes.divider} />
                    <Tooltip title="Aumentar Volume">
                        <Fab color="primary" aria-label="Aumentar Volume" size="small" className={classes.button} onClick={props.volumeUp}>
                            <VolumeUpIcon style={{ fontSize: 25, color: 'white' }} />
                        </Fab>
                    </Tooltip>
                    <Tooltip title="Diminuir Volume">
                        <Fab color="primary" aria-label="Diminuir Volume" size="small" className={classes.button} onClick={props.volumeDown}>
                            <VolumeDownIcon style={{ fontSize: 25, color: 'white' }} />
                        </Fab>
                    </Tooltip>
                </Grid>
                <Grid item className={classes.sendAction}>
                    <MuiThemeProvider theme={themeButtonSend}>
                        <Tooltip title="Enviar">
                            <Fab color="primary" aria-label="enviar" className={classes.buttonSend} onClick={props.send}>
                                <PlayCircleFilledIcon style={{ fontSize: 40, color: 'white' }} />
                            </Fab>
                        </Tooltip>
                    </MuiThemeProvider>
                </Grid>
            </Grid>
        </div>
    )
}