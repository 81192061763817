import axios from 'axios';
import { ApiErrorException, HandleLoginErrorException, HandleForgotPasswordErrorException } from './Exceptions';
import { JwtHelper } from './JwtHelper';

class KeycloakService {
    constructor() {
        this.accessToken = '';
        this.refreshToken = '';
        this.tokenParsed = '';
        this.api = axios.create({
            baseURL: process.env.REACT_APP_BASE_URL,
            timeout: 6000,
        });
    }

    configureInterceptors = (showLoader, hideloader) => {
        this.api.interceptors.request.use(function (config) {
            showLoader()
            return config
        }, function (error) {
            hideloader()
            return Promise.reject(error);
        });

        this.api.interceptors.response.use(function (response) {
            hideloader()
            return response;
        }, function (error) {
            hideloader()
            return Promise.reject(error);
        });
    }

    login = data => {
        return new Promise((resolve, reject) => {
            const config = {
                'username': data.username,
                'password': data.password,
                'client_id': 'tinbotPWA',
                'grant_type': 'password',
                'scope': 'openid offline_access quickapp_api',
                'client_secret': 'MobileAppClientSecret'
            }

            const formattedConfig = Object.keys(config).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(config[key])).join('&');

            this.api
                .post(
                    `${process.env.REACT_APP_BASE_URL}/connect/token`,
                    formattedConfig,
                    {
                        headers: {
                            "Content-Type": "application/x-www-form-urlencoded",
                        }
                    }
                )
                .then(response => {
                    this.setAccessToken(response.data, data.rememberMe);

                    resolve(response.data);
                })
                .catch(error => {
                    reject(HandleLoginErrorException(error));
                });
        });
    };

    logout = () => {
        return new Promise((resolve, reject) => {
            if (localStorage.getItem('keycloak@token') !== null) {
                localStorage.removeItem('keycloak@token')
            }

            if (localStorage.getItem('keycloak@rememberMe') !== null) {
                localStorage.removeItem('keycloak@rememberMe')
            }
            
            sessionStorage.clear()

            resolve();
        });
    };

    forgotPassword = (email) => {
        return new Promise((resolve, reject) => {
            this.api
                .post(`${process.env.REACT_APP_BASE_URL}/api/person/forgotPassword`, 
                JSON.stringify(email),
                {
                    headers: {
                        "Content-Type": "application/json"
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(HandleForgotPasswordErrorException(error));
                });
        });
    }

    loadSession = () => {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('keycloak@token')
            const rememberMe = localStorage.getItem('keycloak@rememberMe')

            if (token) {
                this.setAccessToken(JSON.parse(token), rememberMe)
                resolve(true)
            }
            else {
                const sessionToken = sessionStorage.getItem('keycloak@token')
                const sessionRememberMe = sessionStorage.getItem('keycloak@rememberMe')

                if (sessionToken) {
                    this.setAccessToken(JSON.parse(sessionToken), sessionRememberMe)
                    resolve(true)
                }

                resolve(false)
            }
        });
    };

    isTokenExpired = () => {
        if (!this.tokenParsed) return true;

        const expiresIn =
            this.tokenParsed.exp - Math.ceil(new Date().getTime() / 1000);

        return expiresIn < 0;
    };

    updateToken = () => {
        return new Promise((resolve, reject) => {
            const config = {
                'client_id': 'tinbotPWA',
                'grant_type': 'refresh_token',
                'client_secret': 'MobileAppClientSecret'
            }

            const formattedConfig = Object.keys(config).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(config[key])).join('&');

            this.api
                .post(
                    `${process.env.REACT_APP_BASE_URL}/connect/token`,
                    formattedConfig,
                    {
                        headers: {
                            "Content-Type": "application/x-www-form-urlencoded"
                        }
                    }
                )
                .then(response => {
                    if (!response) {
                        reject(new Error('login expired'));
                    }

                    this.setAccessToken(response.data);
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                });
        });
    };

    setAccessToken = (token, rememberMe) => {
        const { access_token, refresh_token, expires_in } = token;

        this.accessToken = access_token;
        this.refreshToken = refresh_token;
        this.expiresIn = expires_in;

        const jwtHelper = new JwtHelper();

        this.tokenParsed = jwtHelper.decodeToken(access_token);

        this.api.defaults.headers.Authorization = `Bearer ${access_token}`;

        if (rememberMe) {
            if (localStorage.getItem('keycloak@token') === null) {
                localStorage.setItem('keycloak@token', JSON.stringify(token));
            }
            if (localStorage.getItem('keycloak@rememberMe') === null) {
                localStorage.setItem('keycloak@rememberMe', rememberMe);
            }
        } else {
            if (sessionStorage.getItem('keycloak@token') === null) {
                sessionStorage.setItem('keycloak@token', JSON.stringify(token));
            }
        }
    };

    get = async (route, params) => {
        try {
            if (this.isTokenExpired()) {
                await this.updateToken();
            }
            const response = await this.api.get(route, params);
            return response;
        } catch (err) {
            throw ApiErrorException(err);
        }
    };

    post = async (route, body) => {
        try {
            if (this.isTokenExpired()) {
                await this.updateToken();
            }
            const response = await this.api.post(route, body);
            return response;
        } catch (err) {
            throw ApiErrorException(err);
        }
    };

    put = async (route, body) => {
        try {
            if (this.isTokenExpired()) {
                await this.updateToken();
            }
            const response = await this.api.put(route, body);
            return response;
        } catch (err) {
            throw ApiErrorException(err);
        }
    };

    delete = async (route, body) => {
        try {
            if (this.isTokenExpired()) {
                await this.updateToken();
            }
            const response = await this.api.delete(route, body);
            return response;
        } catch (err) {
            throw ApiErrorException(err);
        }
    };
}

export default KeycloakService;
