import React from 'react';
import HeaderComponent from './HeaderComponent';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { robotNotSelected } from  '../../store/reducers/Header'

class Header extends React.Component {
    render() {
        return (
            <HeaderComponent
                isLogged={this.props.isLogged}
                isRobotSelected={this.props.isRobotSelected}
                selectedLeafTeamName={this.props.leafTeamName}
            />
        )
    }
}

const mapStateToProps = state => {
    return {
        isLogged: state.header.isLogged,
        isRobotSelected: state.header.isRobotSelected,
        leafTeamName: state.leafTeam.name
    }
};
const mapDispatchToProps = dispatch => bindActionCreators({
    robotNotSelected
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Header)