import React from 'react';
import { List, ListItem, Button } from '@material-ui/core';
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';

export default function LeafTeamListComponent(props) {
    return (
        <List component="nav">
            {
                props.tinbots.map((item, index) => (
                    <div key={`${item.name}-${index}`}>
                        <ListItem
                            onClick={e => props.redirectToOptions(item.id, item.name)}
                            key={`${item.name}-${index}`}                            
                        >
                            <Button 
                                variant="outlined" 
                                fullWidth={true}
                                className="leaf-team-list-item-button"
                                color="primary"
                                endIcon={
                                    <FiberManualRecord className={item.status === 'online' ? "leaf-team-online" : "leaf-team-offline" }/>
                                }
                            >
                                {item.name}
                            </Button>
                        </ListItem>
                    </div>
                ))
            }
        </List>
    )
}