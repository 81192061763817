import React from 'react';
import { Snackbar, SnackbarContent, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    snackbar: {
        [theme.breakpoints.down('xs')]: {
            bottom: 100
        }
    }
}))

export default function MessageAlertComponent(props) {
    // const StyledButton = withStyles({
    //     root: {
    //         background: props.backgroundColor,
    //         color: props.color
    //     }
    // })(Button)

    const classes = useStyles()

    return (
        <Snackbar
            open={props.open}
            autoHideDuration={5000}
            onClose={props.closeAlert}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            className={classes.snackbar}
        >
            <SnackbarContent
                style={{
                    backgroundColor: props.backgroundColor,
                    color: props.color
                }}
                message={props.message}
                action={
                    <React.Fragment>
                        {/* <StyledButton size="small" onClick={props.closeAlert}>
                            FECHAR
                            </StyledButton> */}
                        <IconButton size="small" aria-label="close" color="inherit" onClick={props.closeAlert}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>
                }
            />
        </Snackbar>
    )
}