import React from 'react';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Fade from '@material-ui/core/Fade';

import logo from '../../assets/logotinbot.png';
import { Grid, Container } from '@material-ui/core';
import ForgotPassword from '../forgot-password/ForgotPassword';
import ButtonPWA from '../pwa/ButtonPWA'

export default function LoginComponent(props) {

	return (
		<Fade in>
			<Container id="login-container">
				<Grid container id="login-grid-container">
					<Grid item className="login-grid-container-item">
						<div>
							<div className="image-centralized">
								<img src={logo} alt="Logo Tinbot" width={40} height={40} />
							</div>
							<div className="login-title">
								<span>Login</span>
							</div>
						</div>
					</Grid>
					<Grid item className="login-grid-container-item">
						<TextField
							label="Usuário"
							fullWidth
							name="username"
							value={props.state.username}
							onChange={props.handleChangeUsername}
							margin="dense"
							error={props.state.userHasError}
							helperText={props.state.userHasError ? props.state.userHasErrorText : ''}
							autoComplete="off"
							autoFocus={true}
							inputRef={props.usernameInput}
						/>
						<TextField
							fullWidth
							label="Senha"
							name="password"
							type="password"
							value={props.state.password}
							onChange={props.handleChangePassword}
							margin="dense"
							error={props.state.passwordHasError}
							helperText={props.state.passwordHasError ? props.state.passwordHasErrorText : ''}
						/>
						<FormControlLabel
							className="checkbox"
							label="Lembrar-me?"
							control={
								<Checkbox
									name="rememberMe"
									value={props.state.rememberMe}
									onChange={props.handleChangeRememberMe}
									color="primary"
								/>
							} />
					</Grid>
					<Grid item className="login-grid-container-item button-centralized">
						<Button variant="contained" color="primary" onClick={props.login}>
							Login
						</Button>
					</Grid>
					<Grid item className="login-grid-container-item button-centralized">
						<ForgotPassword />
					</Grid>
					<ButtonPWA deferredPrompt={props.deferredPrompt}/>
				</Grid>
			</Container>
		</ Fade>
	)
}    
