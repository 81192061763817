import React from 'react'
import { Fade, Button, Paper, Grid, Container } from '@material-ui/core'
import controllIcon from '../../assets/remotecontroll.png';

export default function OptionsComponent(props) {
    return (
        <Fade in>
            <Container id="options-container">
                <Grid container id="options-grid-container">
                    <Grid item>
                        <Button onClick={props.toRemoteControl}>
                            <Paper elevation={5}>
                                <div >
                                    <img src={controllIcon} className="Card-image" width="100px" alt="Controle Remoto" />
                                </div>
                                <div >
                                    <span>Controle remoto</span>
                                </div>
                            </Paper>
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </Fade>
    )
}