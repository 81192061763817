import LocalAction from "../classes/LocalAction"

class LocalActionStorageHelper {
    constructor() {
        this.localStorageKey = ''
    }

    setLocalStorageKeyName(username) {
        this.localStorageKey = 'remoteControl@' + username
    }

    loadLocalActionsList() {
        let list = []
        let localList = localStorage.getItem(this.localStorageKey);
        if (localList !== null) {
            list = JSON.parse(localStorage.getItem(this.localStorageKey));
        }

        list = this.ensureAtLeastOneLocalActionOnList(list);

        if (localList === null) {
            this.saveLocalActionsList(list)
        }

        return list;
    }

    ensureAtLeastOneLocalActionOnList(list) {
        if (list.length === 0) {
            let localAction = new LocalAction(';) o/ Oi >< Tinbot!', 0, false);
            list.push(localAction);
        }
        return list;
    }

    saveLocalActionsList(list) {
        localStorage.setItem(this.localStorageKey, JSON.stringify(list))
    }
}

export const localActionStorageHelper = new LocalActionStorageHelper();
