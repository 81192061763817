import { combineReducers } from 'redux';

import forgotPassword from './ForgotPassword'
import messageAlert from './MessageAlert'
import leafTeam from './LeafTeam'
import header from './Header'
import login from './Login'
import remoteControl from './RemoteControl'

export default combineReducers({
	forgotPassword,
	messageAlert,
	leafTeam,
	header,
	login,
	remoteControl
});
