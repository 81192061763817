import React, { Component } from "react";

import {
  Router,
  Switch,
  Route,
} from "react-router-dom";

import Login from "./components/login/Login";
import Options from "./components/options/Options"
import LeafTeamList from "./components/leaf-team-list/LeafTeamList";
import RemoteControl from "./components/remote-control/RemoteControl";
import api from './utils/Api'
import history from './utils/History'
import SnackbarUtils from './utils/SnackbarConfigurator'

class OurRouter extends Component {
  render() {
    return (
      <Router history={history}>
        <Switch>
          <Route
            exact path="/"
            render={() => (
              <Login deferredPrompt={this.props.deferredPrompt} />
            )}
          />
          <Route
            exact path="/login"
            render={() =>
              <Login deferredPrompt={this.props.deferredPrompt} />
            }
          />
          <PrivateRoute exact path="/list">
            <LeafTeamList />
          </PrivateRoute>
          <PrivateRoute exact path="/options">
            <Options />
          </PrivateRoute>
          <PrivateRoute exact path="/remote-control">
            <RemoteControl />
          </PrivateRoute>
          <Route
            path="*"
            render={() => (
              <Login deferredPrompt={this.props.deferredPrompt} />
            )}
          />
        </Switch>
      </Router>
    );
  }
};

function RedirectToLogin() {
  api.loadSession().then((result) => {
    if (result) {
      history.push(history.location.pathname)
    } else {
      SnackbarUtils.warning('Sessão expirada. Realize o login')
      history.push("/login")
    }
  }).catch((error) => {
    SnackbarUtils.warning('Sessão expirada. Realize o login')
    history.push("/login")
  })
}

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={() =>
        !api.isTokenExpired() ? (
          children
        ) : (
            RedirectToLogin()
          )
      }
    />
  );
}

export default OurRouter