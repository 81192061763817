import { createAction, handleActions } from 'redux-actions';

export const showAlert = createAction('SHOW_ALERT')
export const closeAlert = createAction('CLOSE_SHOW');
export const addMessageToQueue = createAction('addSnackbarToQueue');

const initialState = {
    show: false,
    message: '',
    severity: '',
    queue: []
};

export default handleActions(
	{
		[showAlert]: (state, action) => ({ 
            ...state, 
            show: true,
            message: action.payload.message,
            severity: action.payload.severity
        }),
		[closeAlert]: (state) => ({
			...state,
            show: false,
        }),
        [addMessageToQueue]: (state, action) => ({
			...state,
            queue: [...state.queue, action.payload],
        }),
	},
	initialState
);
