import { createAction, handleActions } from 'redux-actions';

export const loggedIn = createAction('LOGGED_IN')
export const loggedOut = createAction('LOGGED_OUT')
export const robotSelected = createAction('ROBOT_SELECTED');
export const robotNotSelected = createAction('ROBOT_NOT_SELECTED');

const initialState = {
    isLogged: false,
    isRobotSelected: false
};

export default handleActions(
	{
        [loggedIn]: state => ({ ...state, isLogged: true, isRobotSelected: false }),
        [loggedOut]: state => ({ ...state, isLogged: false, isRobotSelected: false }),
        [robotSelected]: state => ({ ...state, isRobotSelected: true }),
        [robotNotSelected]: state => ({ ...state, isRobotSelected: false })
	},
	initialState
);
