import React from 'react';
import { withRouter } from 'react-router-dom';import { connect } from 'react-redux';
import { loggedIn, robotSelected } from '../../store/reducers/Header'
import { loadCommands, setLocalStorageKeyName } from '../../thunk/RemoteControlApi'
import ActionsBar from './actions-bar/ActionsBar'
import ListTextarea from './list-textarea/ListTextarea'
import AddButton from './add-button/AddButton'
import history from '../../utils/History'
import SnackbarUtils from '../../utils/SnackbarConfigurator'

class RemoteControl extends React.Component {
    constructor() {
        super()

        this.actionsBarRef = React.createRef()
        this.containerRef = React.createRef()
        this.handleActionsBarScroll = this.handleActionsBarScroll.bind(this)
    }

    componentDidMount() {
        if (this.props.isRobotSelected) {
            this.props.loggedIn()
            this.props.robotSelected()
            this.props.setLocalStorageKeyName(this.props.username);
            this.props.loadCommands();
            window.addEventListener('scroll', this.handleActionsBarScroll)
        } else {
            SnackbarUtils.warning('Robô não selecionado')
            history.push('/list')
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleActionsBarScroll)
    }

    handleActionsBarScroll = () => {
        if (window.pageYOffset >= 1) {
            if (this.actionsBarRef.current.style.top !== 0) {
                this.actionsBarRef.current.style.top = '0px'
                this.containerRef.current.style.paddingTop = '36px'
            }
        } else {
            if (this.actionsBarRef.current.style.top !== 36) {
                this.actionsBarRef.current.style.top = '36px'
                this.containerRef.current.style.paddingTop = '64px'
            }
        }
    }

    render() {
        return (
            <div className="group-multiple-components">
                <ActionsBar actionsBarRef={this.actionsBarRef} />
                <ListTextarea containerRef={this.containerRef}/>
                <AddButton />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        username: state.login.username,
        isRobotSelected: state.header.isRobotSelected
    }
};
const mapDispatchToProps = {
    loggedIn,
    robotSelected,
    loadCommands,
    setLocalStorageKeyName
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RemoteControl))