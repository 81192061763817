import { createAction, handleActions } from 'redux-actions';

export const setUsername = createAction('SET_USERNAME')

const initialState = {
    username: '',
};

export default handleActions(
	{
        [setUsername]: (state, action) => ({ ...state, username: action.payload.username }),
	},
	initialState
);
