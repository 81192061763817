export const ApiErrorException = exception => {
  const messageDefault = 'Ocorreu problema na requisição.';
  const { stack } = new Error();

  let message = messageDefault;
  let data = messageDefault;
  if (exception.response !== undefined) {
    data = exception.response.data;
    message =
      data && Array.isArray(data.errors)
        ? data.errors[0].message
        : messageDefault;
  }

  return {
    data,
    message,
    stack,
  };
};

export const HandleLoginErrorException = exception => {
  let formattedException = ApiErrorException(exception)
  let message = 'Falha ao conectar no servidor. Tente novamente mais tarde'

  if (formattedException.data.error_description === 'invalid_username_or_password') {
    message = 'Usuário ou senha inválido'
  } else if (formattedException.data.error === 'invalid_grant') {
    message = 'Login incorreto ou conta desativada';
  } else {
    if (exception.isAxiosError) {
      if (exception.message === 'Network Error') {
        message = 'Sem conexão com a Internet'
      }
    }
  }

  return message
}

export const HandleForgotPasswordErrorException = exception => {
  const formattedException = ApiErrorException(exception)
  let message = 'Falha ao enviar e-mail. Tente novamente mais tarde'

  if (formattedException.data[0].errorMessage) {
    message = formattedException.data[0].errorMessage
  }

  return message
}

export const HandleSendErrorException = exception => {
  let message = 'Falha ao enviar comando ao robô. Tente novamente mais tarde'
  let formattedException = ApiErrorException(exception)

  if (Array.isArray(formattedException.data)) {
    if (formattedException.data.length > 0) {
      message = formattedException.data[0].errorMessage
    }
  } else {
    if (exception.isAxiosError) {
      if (exception.message === 'Network Error') {
        message = 'Sem conexão com a Internet'
      }
    }
  }

  return message
}